export const generateRandomKey = (): string => {
  let key = "";
  const possibleCharacters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 10; i++) {
    key += possibleCharacters.charAt(
      Math.floor(Math.random() * possibleCharacters.length)
    );
  }

  const unixDate = new Date().getTime(); // Unix timestamp in milliseconds
  key = `${unixDate}_${key}`;

  return key;
};


