import React, { ReactNode } from "react";
import { message } from "antd";
import { ArgsProps } from "antd/lib/message";
import CkIcon, { CkIconNames, CkAvailableIcons } from "../../components/atoms/CkIcon";
import CkButton from "../../components/molecules/CkButton";
import { generateRandomKey } from "../keys";
import "./styles.css";

/**
 * These types define the type of the message and the type of icon that the message will display. 
 */
export type CkMessageType = "info" | "error" | "loading" | "success" | "warning";
export type CkMessageIconType = "default" | "ck" | "none" | CkIconNames;

/**
 * CkMessageProps interface
 * This interface defines the props that the CkMessage function accepts.
 *
 * @interface
 * @extends {MessageType} - Extends from Ant Design's MessageType.
 *
 * @property {CkMessageType} type - This defines the type of the message, defaulting to 'info' if not specified.
 *                                   Valid values are: "info", "error", "loading", "success", "warning".
 * @property {CkMessageIconType} icon - This defines the type of icon to be used in the message, defaulting to 'ck' if not provided.
 *                                       The valid values are "default", "ck", "none" or CkIconNames.
 * @property {string} title - The title of the message, defaulting to an empty string if not provided.
 * @property {ReactNode | string} text - The text or ReactNode to be displayed as content of the message.
 * @property {number} duration - The duration (in seconds) for the message to be displayed, defaulting to 20 seconds if not specified.
 * @property {number} zIndex - The z-index of the displayed message in CSS.
 * @property {boolean} closable - Determines if the message should have a close button, defaulting to 'true' if not specified.
 */
export interface CkMessageProps {
  /**
   * This defines the type of the message, defaulting to 'info' if not specified.
   * Valid values are: "info", "error", "loading", "success", "warning".
   */
  type?: CkMessageType;
  /**
   * This defines the type of icon to be used in the message, defaulting to 'ck' if not provided.
   * The valid values are "default", "ck" or "none".
   */
  icon?: CkMessageIconType;
  /**
   * The title of the message, defaulting to an empty string if not provided.
   */
  title?: string;
  /**
   * The text or ReactNode to be displayed as content of the message.
   */
  text?: ReactNode | string;
  /**
   * The duration (in seconds) for the message to be displayed, defaulting to 20 seconds if not specified.
   */
  duration?: number;
  /**
   * The z-index of the displayed message in CSS.
   */
  zIndex?: number;
  /**
   * Determines if the message should have a close button, defaulting to 'true' if not specified.
   */
  closable?: boolean;
  /**
   * If this value is passed, this notification will be destroyed
   */
  messageToDestroy?: string;
}

/**
 *  This is a helper function which returns the CkIconName based on the passed MessageType.
 *  The icon name is then used by the CkMessage function.
 */
const getCkIconName = (type: CkMessageType) : CkIconNames => {
  if (type === "success") return "checkmark-round";
  if (type === "error") return "close-round";
  return "information-round";
}

export const destroyCkMessage = (key: string) => {
  if (!!key === false) return;
  const messageContent = document.querySelector(`[data-message-key="${key}"]`);
  if (!!messageContent === false) return;
  const parentNode = messageContent.parentElement?.parentElement?.parentElement?.parentElement;
  parentNode && parentNode?.classList.contains("ck-message") && parentNode.remove();
}

export const configCkMessage = () => {
  message.config({
    duration: 20,
    maxCount: 1,
    getContainer: () => document.querySelector("main.ant-layout-content") || document.body
  });
}

/**
 *  The main function CkMessage, responsible for creating and displaying the message.
 *  Extends AntD Message, see full doc in https://4x.ant.design/components/message/
 *  @param {CkMessageProps} type - type of message, icon type, title, text, duration, zIndex, closability
 *  @returns {void}
 */
const CkMessage = ({ type = "info", icon = "ck", title = "", text, duration = 20, zIndex, closable = true, messageToDestroy }: CkMessageProps) : string => {
  // Create key
  const key = generateRandomKey();

  // Setup args
  const args: ArgsProps = {
    className: `ck-message ${icon === "none" ? "no-icon" : ""}`,
    content: (
      <div className="message-content" data-message-key={key}>
        <div className="text-wrapper">
          {title && <p className="title">{title}</p>}
          {text && <p className="text">{text}</p>}
        </div>
        {closable && type !== "loading" && (
          <CkButton
            icon="close"
            onClick={() => {message.destroy(key); destroyCkMessage(key)}}
            shape="circle"
            variant="link"
          />
        )}
      </div>
    ),
    key: key,
    duration: duration,
    ...(zIndex ? { style: { zIndex }} : {}),
    ...(icon === "ck" && type !== "loading" ? { icon: <CkIcon name={getCkIconName(type)} /> } : {}),
    ...(icon === "ck" && type === "loading" ? { icon: null } : {}),
    ...(CkAvailableIcons.includes(icon as CkIconNames) ? { icon: <CkIcon name={icon as CkIconNames} /> } : {}),
    ...(closable ? {onClick: () => { destroyCkMessage(key)} } : {})
  };

  // Destroy previous message if is needed
  messageToDestroy && message.destroy(messageToDestroy);

  // Send messasge
  message[type](args);

  // Return key
  return key;
};


export default CkMessage;
